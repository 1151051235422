import Const from '../config/const';
import { fromMobileApp } from '../../common/config/utils';

export const setMessageToAppData = (data) => (dispatch) => {
  if (!fromMobileApp) return;
  dispatch({
    type: Const.messageToApp.setData,
    data
  });
};
