import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

function Spiner({
  className, loaderColor, loaderSize, loaderBorder, loaderContainerSize
}) {
  const getConteinerClass = () => {
    if (className) return className;
    return classes.loaderConteiner;
  };

  const renderSpiner = () => (
    <div className={ getConteinerClass() }>
      <div className={ classes.loader } style={ { '--loaderContainerSize': loaderContainerSize || '90px' } }>
        <div style={ { '--loaderColor': loaderColor, '--loaderSize': loaderSize || '70px', '--loaderBorder': loaderBorder || '10px' } } />
        <div style={ { '--loaderColor': loaderColor, '--loaderSize': loaderSize || '70px', '--loaderBorder': loaderBorder || '10px' } } />
        <div style={ { '--loaderColor': loaderColor, '--loaderSize': loaderSize || '70px', '--loaderBorder': loaderBorder || '10px' } } />
        <div style={ { '--loaderColor': loaderColor, '--loaderSize': loaderSize || '70px', '--loaderBorder': loaderBorder || '10px' } } />
      </div>
    </div>
  );

  return renderSpiner();
}

Spiner.propTypes = {
  className: PropTypes.string,
  loaderColor: PropTypes.string,
  loaderSize: PropTypes.string,
  loaderBorder: PropTypes.string,
  loaderContainerSize: PropTypes.string
};

Spiner.defaultProps = {
  className: null,
  loaderColor: 'var(--app-color)',
  loaderSize: null,
  loaderBorder: null,
  loaderContainerSize: null
};

export default Spiner;
