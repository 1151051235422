/* eslint-disable object-property-newline */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import {
  getTopImage, getTopSection, getBottomSection, rednerCloseIcon, renderGeneralMessage
} from './processing_status_utils';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { trackPageVisit } from '../../../actions/analytics';

const trackPageVisitHandler = (okMessage, isAffiliatePromoPopup, errorType) => {
  if (okMessage && !isAffiliatePromoPopup) trackPageVisit('payment received popup');
  if (errorType === 'spending_limits') trackPageVisit('increase spending limit popup');
};

function ProcessingStatus({
  purchaseResponce: {
    okMessage, cashbackAmount, errorMessage, recoverable, retriable,
    errorType
  },
  t, onFocusProcessingWindow, userLoading, onRetry, onConfirm, purchaseURL, isCreditCard, onClose,
  buttonTitle, chatType, isSideMenu, isAffiliatePromoPopup, purchaseAmount, bonus, error, visible
}) {
  useEffect(() => {
    trackPageVisitHandler(okMessage, isAffiliatePromoPopup, errorType);
  }, [errorType, okMessage]);

  const renderTopImg = () => {
    if (isAffiliatePromoPopup || errorMessage) {
      return (
        <img
          className={ isAffiliatePromoPopup ? classes.topImageAffiliatePromoPopup : classes.topImage }
          src={ getTopImage({ isAffiliatePromoPopup, errorMessage }) }
          alt=""
        />
      );
    }
    return null;
  };

  const renderProcessingStatus = () => (
    <AppGeneralPopup
      visible={ visible }
      logoImg={ renderTopImg() }
      theme={  appGeneralPopupTheme.CENTERED }
      customTopHeader={ rednerCloseIcon({
        okMessage, errorType, isAffiliatePromoPopup, onClose
      }) }
    >
      <div className={ classes.dialogContainer }>
        <div>
          { getTopSection({
            errorMessage, t, okMessage,  isAffiliatePromoPopup, purchaseAmount, bonus
          }) }
        </div>
        <br className={ classes.dialogContainerBr } />
        <div style={ { display: 'flex' } }>
          { renderGeneralMessage({
            isAffiliatePromoPopup, okMessage, bonus, errorMessage, error, isCreditCard,
            onFocusProcessingWindow
          }) }
        </div>
        <br className={ classes.dialogContainerBr } />
        <div className={ classes.bottomRow }>
          { getBottomSection({
            okMessage, errorMessage, error, userLoading, errorType, purchaseURL, onRetry,
            recoverable, retriable, t, isAffiliatePromoPopup, onConfirm, buttonTitle,
            cashbackAmount, isSideMenu, chatType, isCreditCard
          }) }

        </div>
      </div>
    </AppGeneralPopup>
  );

  return renderProcessingStatus();
}

ProcessingStatus.propTypes = {
  purchaseResponce: PropTypes.shape({
    errorMessage: PropTypes.string,
    okMessage: PropTypes.string,
    cashbackAmount: PropTypes.string,
    recoverable: PropTypes.bool,
    retriable: PropTypes.bool,
    errorType: PropTypes.string
  }),
  t: PropTypes.func.isRequired,
  onFocusProcessingWindow: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userLoading: PropTypes.bool,
  isCreditCard: PropTypes.bool,
  purchaseURL: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  chatType: PropTypes.string.isRequired,
  isSideMenu: PropTypes.bool.isRequired,
  isAffiliatePromoPopup: PropTypes.bool.isRequired,
  purchaseAmount: PropTypes.number.isRequired,
  bonus: PropTypes.number.isRequired,
  error: PropTypes.object,
  visible: PropTypes.bool.isRequired
};

ProcessingStatus.defaultProps = {
  purchaseResponce: {},
  userLoading: false,
  isCreditCard: false,
  onFocusProcessingWindow: null,
  error: null
};

export default ProcessingStatus;
