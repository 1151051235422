import  { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCreditCardProcessingPurchaseResponceApi } from 'app/config/api';
import { getClientPlatformForAPI } from '../config/utils';

const POLL_INTERVAL = 1000;

const PollUserCreditStatus = ({ id, onChange }) => {
  const doPoll = () => {
    getCreditCardProcessingPurchaseResponceApi(id, { platform: getClientPlatformForAPI() }).then(response => {
      const { errorMessage, okMessage } = response;

      // TODO: parse and handle response fields
      if (errorMessage || okMessage) {
        onChange(!!okMessage, response);
        return;
      }
      setTimeout(doPoll, POLL_INTERVAL);
    });
  };

  useEffect(() => {
    doPoll();
  }, []);

  return null;
};

PollUserCreditStatus.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PollUserCreditStatus;
