/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PaymentOptionTypes, DefaultPaymentOptionValues, PaymentSourcesListType } from '../payment_methods_list/payment_sources_prop_types';
import classes from './classes.module.scss';
import PaymentMethod from '../payment_methods_list/payment_method/payment_method';
import { paymentSourcesTheme } from '../config/const';
import AddPaymentMethod from '../payment_methods_list/add_payment_method';
import {
  fromMobileApp, hideEmailAdress, isPGW, isWebView
} from '../config/utils';
import  AddApplePayOption  from './apple_pay/add_apple_pay_option';
import { applePayIsSupported } from '../purchase_details_common/purchase_details_common_utils';

const paypalIcon = require('./img/paypal_icon.svg');
const paymentArrowPurple = require('./img/payment_arrow_purple.svg');
const paymentArrow = require('./img/payment_arrow.svg');
const iapIcon = require('./img/iap_icon.svg');

const updatedPurchaseURL = ({ purchaseURL, extraParams }) => {
  if (extraParams) {
    const purchaseURLSearch = purchaseURL.slice(purchaseURL.indexOf('?'));
    const purchaseURLPathName = purchaseURL.slice(0, purchaseURL.indexOf('?'));
    const parsedPurchaseURLSearch = queryString.parse(purchaseURLSearch);
    const parsedExtraParams = queryString.parse(extraParams);
    const updatedUrlSearch = queryString.stringify({
      ...parsedPurchaseURLSearch, ...parsedExtraParams
    });
    const updatedUrl = `${ purchaseURLPathName }?${ updatedUrlSearch }`;
    return updatedUrl;
  }
  return purchaseURL;
};

export const isDefaultePaymentSource = ({ existing }) => {
  if (!existing) return null;
  return existing.find((s) => s.isDefault);
};

const renderAddSourceForKind = ({
  kind, addCreditCard, available, theme, existing
}) => {
  if (!available) return null;
  const source = available.find((s) => s.kind === kind);
  if (!source) return null;
  return (
    <AddPaymentMethod
      addSource={ addCreditCard }
      { ...source }
      key={ source.kind }
      theme={ theme }
      existing={ existing }
    />
  );
};

const renderArrowImg = () => {
  let arrowIcon = paymentArrow;
  if (isPGW()) arrowIcon = paymentArrowPurple;
  return <img src={ arrowIcon } alt="" className={ classes.paymentArrow } />;
};

const renderPPPaymentOption = ({ context, showPaymentSources }) => {
  if (!context.paypalBillingAgreementEmail) return null;
  return (
    <button
      type="button"
      className={ classes.availableOptionButton }
      onClick={ () => showPaymentSources() }
    >
      <div className={ classes.availableOptionButtonTextContainer }>
        <div className={ classes.optionName }>
          <img src={ paypalIcon } alt="" style={ { paddingRight: '14px' } } />
          { hideEmailAdress(context.paypalBillingAgreementEmail) }
        </div>
        { context.error }
      </div>
      { renderArrowImg() }
    </button>
  );
};

const renderInAppPaymentOption = ({ showPaymentSources, t }) => (
  <button
    type="button"
    className={ classes.availableOptionButton }
    onClick={ () => showPaymentSources() }
  >
    <div className={ classes.availableOptionButtonTextContainer }>
      <div className={ classes.optionName }>
        <img src={ iapIcon } alt="" style={ { paddingRight: '14px' } } />
        { t('purchase.in_app_purchase') }
      </div>
    </div>
    { renderArrowImg() }
  </button>
);

const getDefaulteOrExistingOption = ({ defaulte, existing }) => defaulte || existing[0];

const renderPaymentOption = ({
  existing, kind, renderSource, context, showPaymentSources, t
}) => {
  const defaulte = isDefaultePaymentSource({ existing });
  if (kind === 'iap') return renderInAppPaymentOption({ showPaymentSources, t });
  if (kind === 'pp' && !defaulte) return renderPPPaymentOption({ context, showPaymentSources });
  if (existing && existing.length > 0) {
    return renderSource(getDefaulteOrExistingOption({ defaulte, existing }));
  }
  return null;
};

const renderAddPaymentOptionsForKind = ({
  kind, paymentProcessing, currentPaymentOption, purchaseURL, extraParams, purchaseAmount,
  available, theme, existing, addCreditCard, isPayg
}) => {
  if (kind === 'pp') {
    if (isPayg) return null;
    return paymentProcessing(
      currentPaymentOption,
      updatedPurchaseURL({ purchaseURL, extraParams }),
      purchaseAmount,
      () => (renderAddSourceForKind({
        kind: 'pp', available, theme, existing
      }))
    );
  }
  return renderAddSourceForKind({
    kind: 'cc', addCreditCard, available, theme, existing
  });
};

function Option({
  adding, extraParams, kind, errorText, context, purchaseURL, paymentProcessing,
  showPaymentSources, addCreditCard, loading, theme, paymentSources, options,
  changingSource, purchaseAmount, isPayg
}) {
  const { t } = useTranslation();
  const { existing, available } = paymentSources;
  const currentPaymentOption = { kind, context };

  const renderAddPaymentOptions = () => {
    if (kind === 'pp' && isWebView() && !fromMobileApp)  return  null;
    if (kind === 'pp' && isPayg) return null;
    // if (kind === 'ap' && isPayg || !window.ApplePaySession) return null;
    if (kind === 'ap') {
      if (isPayg || !applePayIsSupported()) return null;
      return (
        <AddApplePayOption topPadding={ 15 } />
      );
    }
    return (
      <div className={ classes.paymentOption }>
        {renderAddPaymentOptionsForKind({
          kind,
          paymentProcessing,
          currentPaymentOption,
          purchaseURL,
          extraParams,
          purchaseAmount,
          available,
          theme,
          existing,
          addCreditCard,
          isPayg
        }) }
      </div>
    );
  };

  const renderSource = (s) => (
    <PaymentMethod
      key={ s.id }
      { ...s }
      onEditSource={ showPaymentSources }
      managePaymentSource={ showPaymentSources }
      theme={ theme }
      loading={ loading }
      options={ options }
      errorText={ errorText }
      changingSource={ changingSource }
      isPayg={ isPayg }
      isPaymentOption
    />
  );

  const renderOption = () => {
    if (adding) return renderAddPaymentOptions();
    if (loading) return renderSource({});
    return renderPaymentOption({
      existing, kind, renderSource, context, showPaymentSources, t
    });
  };

  return renderOption();
}

Option.propTypes = {
  ...PaymentOptionTypes,
  purchaseURL: PropTypes.string.isRequired,
  paymentProcessing: PropTypes.func,
  showPaymentSources: PropTypes.func,
  addCreditCard: PropTypes.func,
  paymentSources: PropTypes.shape(PaymentSourcesListType),
  loading: PropTypes.bool.isRequired,
  options: PropTypes.array,
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet]),
  changingSource:PropTypes.bool.isRequired,
  purchaseAmount: PropTypes.number,
  isPayg: PropTypes.bool
};

Option.defaultProps = {
  ...DefaultPaymentOptionValues,
  paymentProcessing: null,
  showPaymentSources: null,
  addCreditCard: null,
  paymentSources: null,
  theme: paymentSourcesTheme.purchase,
  options:[],
  purchaseAmount: null,
  isPayg: null
};

export default Option;
